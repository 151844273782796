import {
	chakra,
	InputProps as ChakraInputProps,
	Popover,
	PopoverContent,
	PopoverProps,
	PopoverTrigger,
	useDisclosure,
	useMultiStyleConfig,
	useOutsideClick,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import {
	DayPicker,
	DayPickerBase,
	useInput,
	UseInputOptions,
} from "react-day-picker";
import "react-day-picker/dist/style.css";
import { useTranslatedString } from "src/i18n/i18n";
import { DatePickerInput } from "./DatePickerInput";
import { dateFormat, locale } from "./utils";

type DatePickerProps = PopoverProps & {
	name?: string;
	onChange?: (d?: Date) => void;
	onBlur?: VoidFunction;
	options?: UseInputOptions;
	dayPickerOptions?: DayPickerBase;
	selected?: Date;
	inputProps?: ChakraInputProps;
};

export const DatePicker: React.FC<DatePickerProps> = ({
	onChange,
	onBlur,
	selected,
	options,
	name,
	dayPickerOptions,
	inputProps: chakraInputProps,
	...rest
}) => {
	const t = useTranslatedString();
	const styles = useMultiStyleConfig("DatePicker");

	const ref = React.useRef<HTMLDivElement | null>(null);
	const { isOpen, onOpen, onClose } = useDisclosure();

	useOutsideClick({
		ref,
		handler: () => {
			onClose();
			isOpen && onBlur?.();
		},
	});

	const today = useMemo(() => new Date(), []);

	const defaultOptions: UseInputOptions = {
		defaultSelected: selected,
		fromDate: today,
		format: dateFormat,
		required: true,
		locale,
	};

	const { dayPickerProps, inputProps } = useInput({
		...defaultOptions,
		...options,
	});

	return (
		<chakra.div __css={styles.wrapper} ref={ref}>
			<Popover
				isOpen={isOpen}
				onClose={onClose}
				closeOnBlur={true}
				placement="bottom"
				matchWidth={true}
				returnFocusOnClose={false}
				// eslint-disable-next-line jsx-a11y/no-autofocus
				autoFocus={false}
				{...rest}
			>
				<PopoverTrigger>
					<DatePickerInput
						inputProps={{ ...inputProps, ...chakraInputProps }}
						isOpen={isOpen}
						onOpen={onOpen}
						value={selected}
					/>
				</PopoverTrigger>
				<PopoverContent>
					<DayPicker
						{...dayPickerProps}
						numberOfMonths={1}
						fixedWeeks={true}
						onDayClick={(day) => {
							onClose();
							onBlur?.();
							onChange?.(day);
						}}
						disabled={{
							dayOfWeek: [0, 6],
						}}
						labels={{
							labelNext: () => t("datepicker.nextMonth"),
							labelPrevious: () => t("datepicker.previousMonth"),
						}}
						{...dayPickerOptions}
					/>
				</PopoverContent>
			</Popover>
		</chakra.div>
	);
};

// 🔬 e2e: contact.spec.ts
