import {
	IconButton,
	Input,
	InputGroup,
	InputProps as ChakraInputProps,
	InputRightElement,
} from "@chakra-ui/react";
import React, { forwardRef } from "react";
import { InputProps } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { useTranslatedString } from "src/i18n/i18n";
import { Calendar } from "src/icons";
import { format } from "./utils";

type DatePickerInputProps = {
	inputProps: InputProps & ChakraInputProps;
	isOpen: boolean;
	onOpen: () => void;
	value: Date | undefined;
};

export const DatePickerInput = forwardRef<
	HTMLInputElement,
	DatePickerInputProps
>(({ inputProps, isOpen, onOpen, value }, ref) => {
	const t = useTranslatedString();

	return (
		<InputGroup>
			<Input
				{...inputProps}
				ref={ref}
				onFocus={onOpen}
				onClick={onOpen}
				placeholder={
					inputProps.placeholder ?? (value ? format(value) : "")
				}
				value={value ? format(value) : ""}
				cursor="pointer"
				readOnly
			/>

			<InputRightElement>
				<IconButton
					variant="link"
					aria-label={
						isOpen ? t("datepicker.close") : t("datepicker.open")
					}
					icon={
						<Calendar
							w={4.5}
							h={4.5}
							fillRule="evenodd"
							color="brand.dark"
						/>
					}
					onClick={onOpen}
					id="click_open_datepicker"
				/>
			</InputRightElement>
		</InputGroup>
	);
});

DatePickerInput.displayName = "DatePickerInput";

// 🔬 e2e: contact.spec.ts
